import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const UserProfile = () => {
  const { user, isAuthenticated, isLoading , getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState(undefined);

  const getAccessTokenField = () => {
    return accessToken ?
      accessToken :
      'Getting Access Token...'
  }


  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const result = await getAccessTokenSilently({
          audience: 'b96941de-8139-4695-a64f-6921192e4b5b',
          scope: 'openid https://api.thomsonreuters.com/auth/shared-capabilities.adn.management-api.read https://api.thomsonreuters.com/auth/shared-capabilities.adn.management-api.write'
        });
        console.log(result)
        setAccessToken(result);
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccessToken();
  }, [accessToken, getAccessTokenSilently]);

    if (isLoading) {
        return <div>Not Logged in...</div>
    }


    return (
        isAuthenticated && (
          <div>
            <img src={user.picture} alt={user.name} />
            <h2>{user.name}</h2>
            <p>{user.email}</p>
            <p>Access Token: {getAccessTokenField()}</p>
          </div>
        )
    );
}

export default UserProfile;