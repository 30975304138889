import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <Auth0Provider
    domain="auth-nonprod.thomsonreuters.com"
    clientId="AXGDJPi7vaTegQYxQD9r8rN4zUaBPCE5"
    audience="b96941de-8139-4695-a64f-6921192e4b5b"
    scope="openid https://api.thomsonreuters.com/auth/shared-capabilities.adn.management-api.read https://api.thomsonreuters.com/auth/shared-capabilities.adn.management-api.write"
    redirectUri={window.location.origin}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
