import './App.css';
import LoginButton from './login/LoginButton';
import UserProfile from './user/UserProfile';

function App() {
  return (
    <div className="App">
        <UserProfile />
        <LoginButton />
    </div>
  );
}

export default App;
