import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    if (isAuthenticated) {
        return <button onClick={() => logout()}>Logout</button>
    }
    return <button onClick={() => loginWithRedirect()}>Login</button>
}

export default LoginButton;
